import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { space, width, position, textAlign } from 'styled-system';
import { Box } from '@square-enix-private/vaquita-ui';
import { transform } from '~/custom/styled-system';

export const FAQContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  ${space};
`;

const ItemContainer = styled.li`
  ${space};
  position: relative;
  display: block;
  &:first-of-type {
    margin-top: 0;
  }
  &:before {
    content: '';
    display: inline-block;
    ${width};
    height: 70px;
    background: url(${({ icon }) => icon});
    background-repeat: no-repeat;
    background-position: center;
    ${position};
    top: 0;
    left: 0;
    ${transform};

    @media screen and (min-width: 580px) {
      top: 16px;
    }
  }
`;

const Question = styled(Box)`
  ${space};
  ${textAlign};
  color: ${({ theme: { colors } }) => colors.text.tertiary};
  font-family: korolev-condensed;
`;

const Answer = styled(Box)`
  ${textAlign};
  & a {
    text-decoration: none;
    color: ${({ theme: { colors } }) => colors.text.primary};
    font-weight: bold;
  }
`;

export const FAQItem = ({ question, answer, icon }) => (
  <ItemContainer
    icon={icon}
    width={['100%', null, '70px']}
    mb={['large', null, 'xlarge']}
    pl={[0, null, '80px']}
    position={['relative', null, 'absolute']}
    transform={[null, null, 'translateY(-50%)']}
  >
    <Question
      upperCase
      fontSize={['26px', 'xlarge']}
      textAlign={['center', null, 'left']}
      mt={['small', 'medium', 0]}
      mx={['large', 0]}
    >
      {question}
    </Question>
    <Answer
      dangerouslySetInnerHTML={{ __html: answer }}
      fontSize={['large', 'medium']}
      textAlign={['center', null, 'left']}
      mt={[0, '10px']}
    />
  </ItemContainer>
);

FAQItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};
