import React from 'react';
import { graphql } from 'gatsby';

import { useTranslation } from 'react-i18next';
import GTMData from '@square-enix-private/fe-gtm/dist/GTMData';
import { Flex } from '@square-enix-private/vaquita-ui';

import SectionTitle from '~/components/SectionTitle/index';
import { FAQItem, FAQContainer } from '~/components/FAQ';

const FAQPage = () => {
  const { t } = useTranslation('faqpage');
  const faqQa = useTranslation('faqQa').t('content', { returnObjects: true });

  return (
    <Flex
      maxWidth="1400px"
      m="auto"
      alignItems="center"
      flexDirection="column"
      pt={['xlarge', 'xxlarge']}
      px={['medium', 'large']}
    >
      <Flex
        width={1}
        justifyContent={['center', 'left']}
        mb={['medium', 'xlarge']}
        mx="0"
      >
        <SectionTitle title={t('title')}>{t('subtitle')}</SectionTitle>
      </Flex>
      <GTMData pageId="faqPage" breadcrumb="faqPage" />
      <FAQContainer mb="medium">
        {faqQa.map(({ question, answer, id }) => (
          <FAQItem
            icon={t('icon.image.src')}
            fill={t('icon.fill')}
            alt={t('icon.title')}
            question={question}
            answer={answer}
            key={id}
          />
        ))}
      </FAQContainer>
    </Flex>
  );
};

export const query = graphql`
  query($locale: String) {
    ...FooterData
    ...GenericData
    ...MetadataData
    ...FlagsData
    ...MenuData
    ...AssetsData
    faqpage(filter: { localeDirectory: { eq: $locale } }) {
      subtitle
      title
      icon {
        fill
        title
        image {
          ...FastlyProps
        }
      }
    }
    faqQa(
      filter: {
        localeDirectory: { eq: $locale }
        question: { ne: "" }
        answer: { ne: "" }
      }
    ) {
      question
      answer
      id
    }
  }
`;

export default FAQPage;
